class Navigation {
    constructor(container) {
        this.container = container;
        this.properties = {
            selectors: {
                navigationList: '.navigation__list',
                toggleButton: '.navigation__toggle'
            },
            classes: {
                navigationActive: 'navigation__list--active',
                navigationExpand: 'navigation__list--expand'
            }
        };

        this.init();
    }

    init() {
        this.cacheElements();
        this.bindEvents();

        this.addAria();
    }

    cacheElements() {
        this.toggleButton = document.querySelector(this.properties.selectors.toggleButton);
        this.navigationList = document.querySelector(this.properties.selectors.navigationList);
    }

    bindEvents() {
        this.toggleButton.addEventListener('click', this.handleClick.bind(this));
    }

    handleClick() {
        if (!this.isAnimationInProgress) {
            this.isAnimationInProgress = true;

            if (this.navigationList.classList.contains(this.properties.classes.navigationActive)) {
                this.toggleOff();
            } else {
                this.toggleOn();
            }
        }
    }

    toggleOn() {
        this.toggleButton.setAttribute('aria-pressed', 'true');
        this.toggleButton.setAttribute('aria-expanded', 'true');
        this.navigationList.classList.add(this.properties.classes.navigationActive);
        this.navigationList.setAttribute('aria-hidden', 'false');

        setTimeout(() => {
            this.navigationList.classList.add(this.properties.classes.navigationExpand);
            this.isAnimationInProgress = false;
        }, 50);
    }

    toggleOff() {
        this.navigationList.classList.remove(this.properties.classes.navigationExpand);
        this.toggleButton.setAttribute('aria-pressed', 'false');

        setTimeout(() => {
            this.toggleButton.setAttribute('aria-expanded', 'false');
            this.navigationList.classList.remove(this.properties.classes.navigationActive);
            this.navigationList.setAttribute('aria-hidden', 'true');
            this.isAnimationInProgress = false;
        }, 500);
    }

    addAria() {
        this.toggleButton.setAttribute('aria-controls', this.navigationList.getAttribute('id'));
    }
}

export default Navigation;