const config = [
  {
    name: "Carousel",
    selector: ".carousel"
  },
  {
    name: "Navigation",
    selector: ".navigation"
  },
  {
    name: "DialogCookies",
    selector: "body"
  },
  {
    name: "TemplateLoader",
    selector: ".load-template"
  },
  {
    name: "OrderOnlineButton",
    selector: ".bella-upmenu-button"
  }
];

export default config;
