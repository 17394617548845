import fetch from "isomorphic-fetch";
import Handlebars from "handlebars";
import domify from "domify";

Handlebars.registerHelper("add", function(a, b) {
  if (
    !isNaN(parseFloat(a)) &&
    isFinite(a) &&
    !isNaN(parseFloat(b)) &&
    isFinite(b)
  ) {
    return Number(a) + Number(b);
  }
  if (typeof a === "string" && typeof b === "string") {
    return a + b;
  }
  return "";
});

import menuList from "../client_templates/menu-list.hbs";
import extras from "../client_templates/extras.hbs";
import types from "../client_templates/types.hbs";
import delivery from "../client_templates/delivery.hbs";
//import venueItem from "../client_templates/venue-item.hbs"; Cannot resolve images on the partial if loaded by JS

class TemplateLoader {
  constructor(container) {
    this.container = container;
    this.templateType = this.container.dataset.templateType;
    this.template = this.container.dataset.template;
    this.dataSource = this.container.dataset.dataSource;

    this.init();
  }

  init() {
    let url;
    if (this.templateType === "MENU_LIST") {
      url = "/data/menus.json";
    } else if (this.templateType === "VENUES") {
      url = "/data/locations.json";
    } else if (this.templateType === "DELIVERY") {
      url = "/data/delivery.json";
    } else if (this.templateType === "MENU_ITEMS") {
      url = "/data/menu_items.json";
    }

    this.handleLoad(url);
  }

  getTemplate() {
    let usedTemp;

    if (this.template === "menuList") {
      usedTemp = menuList;
    } else if (this.template === "extras") {
      usedTemp = extras;
    } else if (this.template === "types") {
      usedTemp = types;
    } else if (this.template === "venueItem") {
      usedTemp = venueItem;
    } else if (this.template === "delivery") {
      usedTemp = delivery;
    }

    return usedTemp;
  }

  loadTemplate(json) {
    let item = json[this.dataSource];

    if (!item) {
      item = json;
    }

    let template = Handlebars.compile(this.getTemplate());
    let result = template(item);
    this.container.parentNode.replaceChild(domify(result), this.container);
  }

  handleLoad(url) {
    fetch(url)
      .then(response => {
        return response.json();
      })
      .then(myJson => {
        this.loadTemplate(myJson);
      });
  }
}

export default TemplateLoader;
