import config from './config/components.config.js';
import { COMPONENTS } from './config/components.const.js';
import LazyLoad from 'vanilla-lazyload';

config.forEach((component) => {
  const componentList = document.querySelectorAll(component.selector);
  componentList.forEach((item) => {
    new COMPONENTS[component.name](item, component.config);
  });
});

new LazyLoad();
