class OrderOnlineButton {
    constructor(container) {
        this.container = container;
        this.properties = {
            selectors: {
                button: '.bella-upmenu-button'
            },
            classes: {
                fixed: 'bella-upmenu-button--fixed',
                loaded: 'bella-upmenu-button--loaded'
            }
        };

        this.handleScroll = this.handleScroll.bind(this);
        this.handleResize = this.handleResize.bind(this);

        this.init();
    }

    init() {
        this.bindEvents();
        this.calculateResizePosition();

        setTimeout(() => {
            this.container.classList.add(this.properties.classes.loaded);
        }, 1000);
    }

    bindEvents() {
        window.addEventListener('scroll', this.debounce(this.handleScroll, 50), {passive: true});
        window.addEventListener('resize', this.debounce(this.handleResize, 300), {passive: true});
    }

    handleScroll(e) {
        e.preventDefault();

        this.calculateScrollPosition();
    }

    handleResize(e) {
        e.preventDefault();

        this.calculateResizePosition();
    }

    calculateResizePosition() {
        const scrollPosition = window.scrollY;

        if (window.innerWidth < 620 && !this.container.classList.contains(this.properties.classes.fixed)) {
            this.toggleOn();
        } else if (window.innerWidth >= 620 && this.container.classList.contains(this.properties.classes.fixed) && scrollPosition === 0) {
            this.toggleOff();
        }
    }

    calculateScrollPosition() {
        const scrollPosition = window.scrollY;

        if (window.innerWidth >= 620) {
            if (scrollPosition > 0 && !this.container.classList.contains(this.properties.classes.fixed)) {
                this.toggleOn();
            } else if (scrollPosition === 0 && this.container.classList.contains(this.properties.classes.fixed)) {
                this.toggleOff();
            }
        } else {
            if (!this.container.classList.contains(this.properties.classes.fixed)) {
                this.toggleOn();
            }
        }
    }

    toggleOn() {
        this.container.classList.add(this.properties.classes.fixed);
    }

    toggleOff() {
        this.container.classList.remove(this.properties.classes.fixed);
    }

    debounce(func, timeout) {
        let timer;
        return (...args) => {
            const next = () => func(...args);
            if (timer) {
                clearTimeout(timer);
            }
            timer = setTimeout(next, timeout > 0 ? timeout : 300);
        };
    }
}

export default OrderOnlineButton;