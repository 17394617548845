import template from '../templates/partials/dialog-cookies.hbs';

class DialogCookies {
    constructor(container) {
        this.container = container;
        this.properties = {
            selectors: {
                closeButton: 'dialog-cookie__button'
            },
            storage: 'bn-cookie'
        };

        this.init();
    }

    init() {
        if (!localStorage.getItem(this.properties.storage)) {
            this.cacheElements();
            this.bindEvents();
            this.showDialog();
        }
    }

    cacheElements() {
        let elem = document.createElement('div');
        elem.innerHTML = template;
        this.cookieDialog = elem.children[0];
    }

    bindEvents() {
        this.cookieDialog.addEventListener('click', this.handleClick.bind(this));
    }

    showDialog() {
        document.body.appendChild(this.cookieDialog);
    }

    handleClick(e) {
        const target = e.target;
        if (target.classList.contains(this.properties.selectors.closeButton)) {
            localStorage.setItem(this.properties.storage, 'seen');
            document.body.removeChild(this.cookieDialog);
        }
    }
}

export default DialogCookies;