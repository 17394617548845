import Carousel from "../components/carousel.component";
import Navigation from "../components/navigation.component";
import DialogCookies from "../components/dialog-cookies.component";
import TemplateLoader from "../components/template-loader.component";
import OrderOnlineButton from "../components/order-online-button.component";

export const COMPONENTS = {
  Carousel,
  Navigation,
  DialogCookies,
  TemplateLoader,
  OrderOnlineButton
};
